
import { useState, useRef, useMemo, useEffect } from "react";
import { Marker } from "react-leaflet";
//import {  Marker } from "react-leaflet-marker";
import L from 'leaflet';


//DataContext


const Pager = ({ nb, nbselected}) => {

var puces = [];
for (let i = 0; i < nb; i++) {  puces.push(i) };

return  nb>1 ? <div className="flex flex-row justify-center items-center">
{  puces.map(p=>
  <div className={"ml-1 p-1 rounded-full " + (p===nbselected? "bg-white" : "bg-white bg-opacity-50 ")  } key={p} ></div>)}
 </div> : <></>;

};

export default Pager;