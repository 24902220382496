
import { useState, useRef, useMemo, useEffect } from "react";
import { Marker } from "react-leaflet";
//import {  Marker } from "react-leaflet-marker";
import L from 'leaflet';


//DataContext


const DivMarker = ({ tools,marker_ ,editable,data,zoom}) => {


console.log("divMarker " +  marker_.id ,marker);



var marker={...marker_ };
  
  const rc = tools.getRc();
const sdisplay =  marker.type &&  data.styles["map-" + marker.type] ?    (  data.styles["map-" + marker.type]["display"]?? "block") :"block";
 // marker.style = { ... data.styles["map-" + marker.type],display:"none" };

  marker.style = { ... data.styles["map-" + marker.type]};
  const id =marker.id;
 marker.style.transform = (marker.angle ?" rotate(" + marker.angle + "deg) ":"") +  "translate(-50%,-50%)";
marker.style["transform-origin"] ="top left";
  var coordinates = [marker.coordinates[0] /1, marker.coordinates[1] / 1]
// console.log(coordinates);
  if (marker.html && data.icons[marker.html]) {
    marker.html =  data.icons[marker.html];
  }
  else marker.html = marker.name;

  marker.latlng = rc.unproject(coordinates);

  const markerRef = useRef(null);
  const [draggable, setDraggable] = useState(editable);

/*
useEffect(()=>{
  setTimeout( ()=>{
var obj = window.document.getElementById('marker-' + marker.id );
if(obj){
 
  obj.style.transform = (marker.style.transform ??"")+ " translate(-"+obj.clientWidth*0.5 +"px,-"+obj.clientHeight+"px )" ;
  obj.style.display = sdisplay;
  console.log("Centrer",obj, obj.clientWidth, obj.clientHeight);
}},10);


},[zoom,marker]);
*/

  const eventHandlers = 

      {
        dragend() {
      
          const markerA = markerRef.current
          if (markerA != null) {
            var marker__ = tools.getMarker(id);
            console.log("drag end ",id);
            marker__.coordinates = tools.convertLatLng(markerA.getLatLng())  
           var data_ = {...data};
           data_.markers[marker_.id] =marker__;
            tools.setData(data_);
           // tools.updateMarker(id, marker__,data);
           // setPosition(markerA.getLatLng())
          }
        },
        click() {
          if (marker && (editable || marker.sites || marker.visit_sheets ))
            tools.selectMarker(marker.id);

        }
      }
   

  if (!marker) {
    return <></>
  }
  const content = L.divIcon({
    iconSize: null,
    html: '<div id="marker-' + marker.id + '" class=" map-' + marker.type + '" style="' + Object.entries(marker.style).map(kv => kv[0] + ":" + kv[1] + ";").join("") + '" ><a href="#'+ marker.id+ '" style="color: inherit;text-decoration: none;">' + marker.html +'</a></div>'
  });



  return <Marker
    ref={markerRef}
    position={[marker.latlng.lat, marker.latlng.lng]}
    draggable={editable}
    eventHandlers={eventHandlers}
    placement="center"
    size={[80, 40]}
    interactive // required for riseOnHover
    riseOnHover

    icon={content}
    rotationAngle={marker.angle}
    rotationOrigin="center"
  >

  </Marker>;

};

export default DivMarker;