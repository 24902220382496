

import {  useState} from 'react';

import "leaflet/dist/leaflet.css";
import "../css/Map.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

import url_server from '../service/url_server';

export default   ({ setToken,setShowLogin }) => {

const [login,setLogin]=useState("");
const [password,setPassword]=useState(0);

  const Connexion = async ()=>{

    const response = await fetch(url_server + 'api/auth/' ,{
      crossDomain:true,
      method:"post",
      headers:{"Content-Type": "application/json"},
  body: JSON.stringify({ login,password})
 });
 const token = await response.json();
 sessionStorage.setItem('token',token);
       setToken(token);
setShowLogin(false);
  };


  return <><div className="   w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 flex flex-col justify-center items-center" >
    <div className="relative p-4 flex flex-col items-center  justify-center box-border  w-80" >
<div className={'flex flex-col  p-2 rounded-lg shadow  relative justify-center items-center  box-border w-full fade bg-white'} > 
<div className="flex flex-row w-full px-4 justify-between items-center">
 <div  className=' text-base font-bold text-right'  >Connexion</div>
  </div>
<div   className='flex flex-col items-center w-full box-border'> 
 
<div className="flex flex-col" ><div className='text-xs mt-2' >Login</div>
          <input type='text' className='text-xs shadow appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name="login" placeholder='Login'  onChange={(event) => { setLogin(event.target.value) }} />
        </div>

        <div className="flex flex-col"  ><div className='text-xs mt-2' >Password</div>
          <input type='password' className='text-xs shadow appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name="password"  placeholder='*****'  onChange={(event) => {setPassword(event.target.value) }} />
        </div>
 
 </div>

<div className='flex flex-row justify-center'  > 
<span className="m-1 p-1 border border-blue-500 text-blue-800 rounded font-bold " onClick={Connexion}> Valider</span></div>
  
  </div>  
  
    <FontAwesomeIcon icon={fa.faClose } onClick={()=>setShowLogin(false)} className=" p-2 text-black rounded-full bg-gray-300 w-4 h-4 absolute right-0 top-0" />
  
  </div>
  </div>

  </>


}