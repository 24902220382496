

import {  useState } from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

export default ({ visitSheets, selectVisitSheet }) => {
  const [loaded, setLoaded] = useState(false);

  const Image = ({ src,className }) => {
    const [loaded, setLoaded] = useState(false);

    return (
      <>
        {loaded ? null : (
          <div 
          className={className + " flex flex-row items-center justify-center fade "}
          ><FontAwesomeIcon  icon={fa.faSpinner}  className=" text-black    spinner" /></div>
        )}
        <img
          style={loaded ? {} : { display: 'none' }}
          className={className +" fade"}
          src={src}
          onLoad={() => setLoaded(true)}
        />
      </>
    );
  };
  
 return <div className="   md:flex   w-screen h-screen bg-black bg-opacity-50 static top-0 left-0 flex-col justify-center items-center relative" >

  <div className="flex flex-col items-center justify-center   bg-white w-6/12 h-2/3 " >
   <div className=" w-full p-4 flex flex-row justify-between"> <h1> Lier une visite au point</h1>     <FontAwesomeIcon icon={fa.faClose} onClick={() => { selectVisitSheet(null)}} className={"p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10" } /> </div>
    <div className="bg-white w-full h-full overflow-y-auto block" >

<div className="flex flex-col items-center justify-center bg-white  w-full" >
{   visitSheets.map( vs =>
            <div className='flex flex-col items-center text-xs shadow m-2 p-2 rounded box-border w-11/12 ' key ={vs.id}>
 <div className='min-h-40 text-xs w-full  box-border  overflow-x-auto block'>  
 <div className='h-full flex flex-row items-center justify-start text-xs  box-border  '>  

  <div className='h-full w-full flex flex-row items-center justify-start text-xs  box-border  '>
  <div className='min-h-40 text-xs w-80 box-border block  p-1'> 
  <h1 className='text-lg'>{vs.title.text_fr}</h1> 
  {
 
 vs.texts.map( content =>  <div className=' p-2'  key ={content.id} >{content.text_fr}</div>  )
}   
</div>
  <div className='flex flex-col ' >
        {
          vs.medias.filter(file=>file.content_type.includes("image")).map(image=> 
            <Image src={image.url}  key ={image.id} className="ml-4 p-2 w-60   h-40 rounded shadow mx-[-20px] object-cover " />   )
        }


            </div>
       
            </div>    
</div>    

 </div>
 <div className='flex flex-row items-end justify-end text-xs w-full m-1 p-2 box-border'>
  
 <div className='flex flex-col items-center text-xs text-white bg-blue-500  p-2 rounded box-border  ' onClick={() => { selectVisitSheet(vs)}} >Sélectionner</div>
  </div>
</div>
)

}
  </div>
  </div>
  </div>
  </div> 
  return  <div className="   md:flex   w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 flex-col justify-center items-center" >
      <div className="flex flex-col items-center justify-center" >

        <div className=" max-w-full  relative p-4 flex flex-row items-center border-box" >
        
          <div className={'   max-w-full flex flex-row  p-2 rounded-lg shadow  relative fade  bg-white'}  >
{   visitSheets.map( vs =>
            <div className='flex flex-row items-center text-xs' key ={vs.id}>

{

  vs.visit_sheet_contents.map( content => 
    <div className='flex flex-col items-center justify-center'  key ={content.id}>
          {
            content.files.filter(file=>file.content_type.includes("image")).map(image=> 
              <Image src={image.url}  key ={image.id} className="w-60   h-40 rounded shadow mx-[-20px] object-cover " />   )
          }
  

              </div>
              
              )
}            

<div  onClick={selectVisitSheet(vs.id)}> Selection </div>

                 </div>
)}

          </div>

        </div>
     
      </div>
    </div>
  


}