import { Outlet, Link ,useLocation} from "react-router-dom";

const Layout = () => {
  const location = useLocation();

  if(location.pathname.startsWith("/map/")) return <> <Outlet /></>
  return (
    <>
    

      <Outlet />
    </>
  )
};

export default Layout;