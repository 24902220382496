import logo from '../logo512.png';
import '../css/Home.css';


function NoPage() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
           404 - No Page Found
          </p>
   
        </header>
      </div>
    );
  }
  
  export default NoPage;