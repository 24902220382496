

import {  useMemo, useState} from 'react';

import "leaflet/dist/leaflet.css";
import "../css/Map.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import Pager from './Pager';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default   ({ sites, setSelectedMarker,dataSites }) => {

const [indexSite,setIndexSite]=useState(0);
const [indexImage,setIndexImage]=useState(0);




const onSwipeEnd = (event)=>{
console.log("onSwipeEnd",event);
}

const Image = ({ src,className }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {loaded ? null : (
        <div 
        className={className + " flex flex-row items-center justify-center fade "}
        ><FontAwesomeIcon  icon={fa.faSpinner}  className=" text-black    spinner" /></div>
      )}
      <img
        style={loaded ? {} : { display: 'none' }}
        className={className +" fade"}
        src={src}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

  const getSite=()=>{
     if(sites.length==0) return dataSites[0];



    var site  = dataSites.find(s=>s.id+""===""+sites[indexSite]);

    if (! site.images) site.images=[];
    return site;
  }

const images = useMemo(()=>{
  var site = getSite();
   return  site.images.length==0 ? [{url:"https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"  }] :site.images;
},[sites,indexSite])

const getImages =()=>{
  var site = getSite();
   return  site.images.length==0 ? [{url:"https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"  }] :site.images;
};


  const linkSite=useMemo( ()=>{
   var site = getSite();

   return  site.link!="" ?site.link:  "https://www.henritrip.fr/touristic-site/" + site.id;
 },[sites,indexSite]);


 const getLinkSite= ()=>{
  var site = getSite();
     if (! site.link) site.images=[];
  return  site.link!="" ?site.link:  "https://www.henritrip.fr/touristic-site/" + site.id;
};



  const getImageUrl=()=>{
  var site = getSite();
  if( site.images.length>0) return  site.images[indexImage].url

  return "https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"  ;
  }


  return <><div className=" landscape:hidden   w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 flex flex-col justify-center items-center" >
    <div className="relative p-4 flex flex-col items-center  justify-center box-border  w-full" >
<div className={'flex flex-col  p-2 rounded-lg shadow  relative justify-center items-center  box-border w-full fade  ' + (sites.length==0 ?"bg-orange-500  text-white":"bg-white")} > 
<div className="flex flex-row w-full px-4 justify-between items-center">
<FontAwesomeIcon icon={fa.faChevronLeft} onClick={()=>{setIndexImage(0); setIndexSite( (indexSite-1 + sites.length ) %  sites.length)}} className={" flex-initial p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 "+ (sites.length>1? "":" opacity-0" )}  />
     <div  className=' text-base font-bold text-center flex-1'  >{ getSite().title}</div>
     <FontAwesomeIcon icon={fa.faChevronRight} onClick={()=>{setIndexImage(0);setIndexSite( (indexSite+1 )% sites.length)}} className={" flex-initial p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10  "+ (sites.length>1? "":" opacity-0" )}  /> 
</div>
<div   className='flex flex-col items-center w-full box-border'> 
  <div   className='flex flex-row items-center w-full p-2 box-border'>   
  <FontAwesomeIcon icon={fa.faChevronLeft} onClick={()=>setIndexImage( (indexImage-1 + getSite().images.length ) % getSite().images.length)} className={" bg-opacity-50 p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 -mr-8 "+ (getSite().images.length>1? "":" opacity-0" )}   />
{ false ?  <Image src={getImageUrl() }   className="w-full box-border rounded shadow max-h-[80rem] object-cover" /> 
  : <Carousel  className="w-full box-border rounded shadow   h-80 max-h-[40vh] object-cover" infiniteLoop="true" selectedItem={indexImage}  showStatus={false} showArrows={false} >
    { getImages().map(img=> <Image src={img.url }   className="w-full box-border rounded shadow  max-h-[40vh]  h-80  object-cover" />   ) }
  </Carousel>
  }  <FontAwesomeIcon icon={fa.faChevronRight} onClick={()=>setIndexImage( (indexImage+1 )% getSite().images.length)} className={"bg-opacity-50  text-black p-2 rounded-full bg-gray-300 w-4 h-4  -ml-8 z-10 "+ (getSite().images.length>1? "":" opacity-0" )}  /> 
  </div>  

</div>
<div className=' text-xs  h-20 overflow-hidden p-4 mb-1'  >{ getSite().description.substring(0,150)}</div>
<div className='flex flex-row justify-center'  > 
<a href={linkSite} target="_blank"><span className="m-1 p-1 border border-blue-500 text-blue-800 rounded font-bold text-xs"> En savoir plus</span></a></div>
  
  </div>  
  <div className='w-full m-1'>  <Pager nb={sites.length} nbselected={indexSite}  /></div>
    <FontAwesomeIcon icon={fa.faClose } onClick={()=>setSelectedMarker(-1)} className=" p-2 text-black rounded-full bg-gray-300 w-4 h-4 absolute right-0 top-0" />
  
  </div>
  </div>

<div className="  portrait:hidden md:flex   w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 flex-col justify-center items-center" >
  <div className="flex flex-col items-center justify-center" >

    <div className=" max-w-full  relative p-4 flex flex-row items-center border-box" >
    <FontAwesomeIcon icon={fa.faChevronLeft} onClick={()=>{setIndexImage(0);setIndexSite( (indexSite-1 + sites.length ) %  sites.length)}} className={"p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 m-1 "+ (sites.length>1? "":" opacity-0" ) } />
     <div className={'   max-w-full flex flex-row  p-2 rounded-lg shadow  relative fade ' + (sites.length==0 ?" bg-orange-500 text-white":" bg-white")}  > 

  <div   className='flex flex-row items-center text-xs'>   

  <FontAwesomeIcon icon={fa.faChevronLeft} onClick={()=>setIndexImage( (indexImage-1 + getSite().images.length ) % getSite().images.length)} className={" bg-opacity-50 p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 "+ (getSite().images.length>1? "":" opacity-0" )}  />
   <div className='flex flex-col items-center justify-center'>
   { false ?  <Image src={getImageUrl() }   className="w-60  h-40 rounded shadow mx-[-20px] object-cover "  /> 
  : <Carousel  className="w-60  h-40 rounded shadow mx-[-20px] object-cover " infiniteLoop="true" selectedItem={indexImage}  showStatus={false} showArrows={false} >
    { getImages().map(img=> <Image src={img.url }   className="w-60  h-40 rounded shadow mx-[-20px] object-cover "  />   ) }
  </Carousel>
  } 

   </div>
   <FontAwesomeIcon icon={fa.faChevronRight} onClick={()=>setIndexImage( (indexImage+1 )% getSite().images.length)} className={"bg-opacity-50 p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 "+ (getSite().images.length>1? "":" opacity-0" )}  /> 
  </div>  



  <div   className='flex flex-col max-w-[20rem] h-40 justify-around p-2'>   

   <div  className=' text-s font-bold  '  >{ getSite().title}</div>


<div className=' text-xs  h-20 overflow-hidden '  >{ getSite().description.substring(0,150)}</div>
<div className='flex flex-row justify-center'  > 
<a href={linkSite} target="_blank"><span className="m-1 p-1 border border-blue-500 text-blue-800 rounded font-bold text-xs"> En savoir plus</span></a></div>
  </div>
  </div>  
  <FontAwesomeIcon icon={fa.faChevronRight} onClick={()=>{setIndexImage(0);setIndexSite( (indexSite+1 )% sites.length)}} className={"p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 m-1 "+ (sites.length>1? "":" opacity-0" )}  /> 
  <FontAwesomeIcon icon={fa.faClose } onClick={()=>setSelectedMarker(-1)} className=" p-2 text-black rounded-full bg-gray-300 w-4 h-4 absolute right-8 top-0" />
  </div>
<div className='w-full -m-1 '>  <Pager nb={sites.length} nbselected={indexSite}  /></div>
  </div>
  </div>
  </>


}