
import { MapContainer } from 'react-leaflet'
import { useEffect, useState, useContext, createContext, useMemo } from 'react';
import { Link, useParams } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import "../css/Map.css"
import { CRS } from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'

import ComponentTileLayer from '../components/ComponentTileLayer';
import PopupSite from '../components/PopupSite';
import PopupVisitSheet from '../components/PopupVisitSheet';

import LoginForm from '../components/LoginForm';
import MarkerForm from '../components/MarkerForm';
import url_server from '../service/url_server';
import VisitSelect from '../components/VisitSelect';
const DataContext = createContext({});


const TheMap = () => {
  const blnPopup = !window.location.href.includes("nopopup");
var {width,height} = document.body.getBoundingClientRect();
console.log(Math.min(width,height)/256)

var defaultZoom  =Math.max(0,Math.log2( (Math.min(width,height)/256)));
console.log("default zoom" + defaultZoom)
  let { id } = useParams();
  const [data, setData] = useState({});
  const [editable, setEditable] = useState(false);
  const [data0, setData0] = useState({});
  const [selectedMarker, setSelectedMarker] = useState(-1)
  const [modified, setModified] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showVisitSelect,setShowVisitSelect]= useState(false);
  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const [visitSheets, setVisitSheets] = useState([]);

  var tools = {
   
   showVisitSelect : function(){

    console.log('showVisitSelect');
    setShowVisitSelect(true);
   },
    getVisitSheets: function(){
      return visitSheets;
  
    },
    getVisitSheetTitle: function(id){
      console.log(visitSheets)
      const v =  visitSheets.find(v=>v.id === id);

  if( !v || !v.title) return 'x ' +id;
return    v.title.text_fr;
    },
    
    getData: function () {
      return data;
    },
    selectMarker: function (id) {
     if (document.referrer && document.referrer.includes("www.henritrip.fr") && window.top) {
       window.top?.postMessage?.(data.markers[id]?.visit_sheets?.[0], "*");
     } else {
       setSelectedMarker(id);
     }
    },
    getMarker: function (id) {
      return { ...data.markers[id] };
    },
    updateMarker: function (id, marker, data__) {
      var data_ = { ...data__ }
      data_.markers[id] = marker;

      saveData({ ...data_ });
    },
    setData: function (data_)
     {
      saveData({ ...data_ });
    },

    save: function () { },
    cancel: function () {
      saveData(data0.schema);
    }
  }

  const isIFrame = useMemo(() => {
    if (window.location !== window.parent.location) {
      // The page is in an iframe
      return true;
    } else {
      // The page is not in an iframe
      return false;
    }
  })

  const saveData = (newData) => {

    setData(JSON.parse(JSON.stringify(newData)));
  }
const selectVisitSheet=(visit_sheet)=>{

if(selectedMarker!=-1 && visit_sheet){
    //  console.log(event.target.value,kv)
    var data_ = { ...data }
    if(! data_.markers[selectedMarker].visit_sheets) data_.markers[selectedMarker].visit_sheets=[]
    data_.markers[selectedMarker].visit_sheets.push(visit_sheet.id)
   
   if(! data_.resources) data_.resources=[];
    data_.resources.push(  { id: visit_sheet.id ,resource_type :"VisitSheet" }  )
       setData({...data_});
  }

setShowVisitSelect(false);
}

  useEffect(() => {
    console.log("modified before", modified);
    console.log(data);
    console.log(data0);
    setModified(JSON.stringify(data) != JSON.stringify(data0.schema));
    console.log("modified after", modified);
  }, [data])
  console.log("modified ", modified);
  useEffect(() => {

    async function fetchData() {
      const response = await fetch(url_server + 'api/map/' + id);
      const json = await response.json();
      json.schema.id = json.id;

      json.schema.markers = enumMarkers(json.schema.markers)
      setData({ ...json.schema });
      setData0(JSON.parse(JSON.stringify({ ...json })));
      if (json.schema.activity_id) {
        const responseA = await fetch(url_server + 'api/activity/' + json.schema.activity_id);
        const ractivity = await responseA.json();
        console.log("Activity", ractivity);
        setVisitSheets(ractivity.visit_sheets);
      }
    }
    fetchData();

  }, []
  );

  const SaveMap = async () => {
    var map = { ...data0 }
    map.schema = data
/* map.schema.markers  =  map.schema.markers.map(marker=>{
    marker.coordinates[0]*=0.32;
    marker.coordinates[1]*=0.32;
     return marker

 })
 */ 
    const response = await fetch(url_server + 'api/map/' + map.id, {
      crossDomain: true,
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token, map })
    });
    var json = await response.json();
    json.schema.id = json.id;
    json.schema.markers = enumMarkers(json.schema.markers)
    setData({ ...json.schema });
    setData0({ ...json });
  }

  const enumMarkers = (markers) => {

    return markers.map((m, i) => {

      return { ...m, id: i };
    });

  }

  const resetToken = async () => {
    setToken(null);
    sessionStorage.removeItem("token")
  }
  const ResetMap = async () => {
    saveData({ ...data0.schema });
  }

  const CreateMarker = async () => {
    data.markers.push(
      { id: data.markers.length, "name": "m-" + data.markers.length, "coordinates": [0, 0], "zoommin": 0, "angle": 0, "type": "","html":"" },

    );
    console.log("CreateMarker", data);
    saveData({ ...data });
    setSelectedMarker(data.markers.length - 1);
  }

  const DuplicateMarker = async () => {
    var marker = { ...data.markers[selectedMarker], id: data.markers.length };
    marker.name += "- copy";
    data.markers.push(
      marker
    );
    saveData({ ...data });
    setSelectedMarker(data.markers.length - 1);
  }


  const DeleteMarker = async () => {
    if (window.confirm("Are you sure you want to delete this marker?")) {
      data.markers = data.markers.filter((m, i) => i != selectedMarker)

      data.markers = enumMarkers(data.markers);

      setSelectedMarker(-1);
      setData({ ...data });

    }
  }

  return <>
    {
    data.markers ?
      <MapContainer id="map"
    
        minZoom={data.minZoom}
        maxZoom={data.maxZoom}
        zoom={defaultZoom}
        scrollWheelZoom={true}
        zoomSnap={0.25}
        zoomDelta={0.5}
        crs={CRS.Simple}>
        <ComponentTileLayer id={data0.id} zoom={defaultZoom} data={data} tools={tools} setData={saveData} data0={data0} setSelectedMarker={setSelectedMarker} editable={editable} />

      </MapContainer> : <div
        className="w-screen h-screen flex flex-row items-center justify-center fade "
      ><FontAwesomeIcon icon={fa.faSpinner} className=" text-black    spinner" /></div>}
    <div className='fixed bottom-0 right-0 z-[1000] p-4  flex flex-col justify-end items-end'  >

      {!isIFrame &&
        editable ? <MarkerForm tools={tools} selectedMarker={selectedMarker} data={data} setData={saveData} setSelectedMarker={setSelectedMarker} data0={data0} /> :
        <></>
      }
      {!token && showLogin ?
        <LoginForm setToken={setToken} setShowLogin={setShowLogin} /> : <></>}
      {isIFrame && <a className="fixed top-4 right-4   " href={"/map/" + id} target="_blank" ><FontAwesomeIcon icon={fa.faExpand} className="bg-black bg-opacity-50 text-white p-1 " /></a>}
      {!isIFrame && showVisitSelect ?
        <VisitSelect visitSheets={visitSheets} selectVisitSheet={selectVisitSheet} /> : <></>}
      {blnPopup && !editable && selectedMarker > -1 && data.markers[selectedMarker].sites  && data.markers[selectedMarker].sites.length>0 ?
        <PopupSite setSelectedMarker={setSelectedMarker} sites={data.markers[selectedMarker].sites} dataSites={(data.resources??[]).filter(r=> r.resource_type!="VisitSheet")   } /> : <></>}
      {blnPopup && !isIFrame &&  !editable && selectedMarker > -1 && data.markers[selectedMarker].visit_sheets && data.markers[selectedMarker].visit_sheets.length>0  ?
        <PopupVisitSheet setSelectedMarker={setSelectedMarker} visit_sheets ={data.markers[selectedMarker].visit_sheets??[]} dataVisitSheets={visitSheets  } /> : <></>}
  
      <div className="  text-2xl flex flex-row justify-end "  >

        {!isIFrame && token ? <>
          {editable && selectedMarker === -1 ? <div className='m-1 bg-white shadow rounded p-2 text-green-500 text-xs flex flex-row' ><FontAwesomeIcon icon={fa.faPlusCircle} className="mx-1" onClick={CreateMarker} />Create</div> : <></>}
          {editable && selectedMarker !== -1 ? <div className='m-1 bg-white shadow rounded p-2 text-green-500 text-xs flex flex-row' ><FontAwesomeIcon icon={fa.faPlusCircle} className="mx-1" onClick={DuplicateMarker} />Dupliquer</div> : <></>}
          {editable && selectedMarker != -1 ? <div className='m-1 bg-white shadow rounded p-2 text-red-500 text-xs flex flex-row' ><FontAwesomeIcon icon={fa.faMinusCircle} className="mx-1" onClick={DeleteMarker} />Supprimer</div> : <></>}
          {modified ? <><div className='m-1 bg-white shadow rounded p-2 text-blue-500 text-xs flex flex-row' ><FontAwesomeIcon icon={fa.faSave} className="mx-1" onClick={SaveMap} />Save</div>
            <div className='m-1 bg-white shadow rounded p-2 text-red-500 text-xs flex flex-row' ><FontAwesomeIcon icon={fa.faCancel} className="mx-1 " onClick={ResetMap} />Cancel</div></> : <></>
          }
          <div className='m-1 bg-white shadow rounded p-2 text-gray-500 text-xs flex flex-row' > <FontAwesomeIcon icon={fa.faEdit} className={!editable ? "text-blue-500 mx-1" : "text-red-500 mx-1"} onClick={() => setEditable(!editable)} />
            {!editable ? "Editer" : "Consulter"}</div></> : <></>}


        {false && !isIFrame && !token ? <div className='m-1 bg-white shadow rounded p-2 text-gray-500 text-xs flex flex-row' > <FontAwesomeIcon icon={fa.faUserCircle} className="text-green-500 mx-1" onClick={() => setShowLogin(true)} />
          Login</div> : <></>}
        {!isIFrame && token ? <><div className='m-1 bg-white shadow rounded p-2 text-gray-500 text-xs flex flex-row' > <FontAwesomeIcon icon={fa.faUserCircle} className="text-red-500 mx-1" onClick={resetToken} />
          Logout</div><Link to="/"><div className='m-1 bg-white shadow rounded p-2 text-gray-500 text-xs flex flex-row' > <FontAwesomeIcon icon={fa.faBackward} className="text-black-500 mx-1" />Retour</div></Link>
        </> :


          <></>



        }
      </div></div>

  </>;

};

export default TheMap;

/*

  <Marker position={[0,0]}>
    <Popup>
     Okay
    </Popup>
  </Marker>



kafka/ 
nats/
expo/ 



PUSH-NOTIFICATIONS -
ID USER - 
ID DEVICES - 

XXXX x fcm
XXXX x apns

*/