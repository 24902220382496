

import {  useMemo, useState} from 'react';

import "leaflet/dist/leaflet.css";
import "../css/Map.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import Pager from './Pager';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default   ({ visit_sheets, setSelectedMarker,dataVisitSheets }) => {



console.log("dataVisitSheets",visit_sheets);

const [indexVisitSheet,setIndexVisitSheet]=useState(0);
const [indexImage,setIndexImage]=useState(0);




const onSwipeEnd = (event)=>{
console.log("onSwipeEnd",event);
}

const Image = ({ src,className }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {loaded ? null : (
        <div 
        className={className + " flex flex-row items-center justify-center fade "}
        ><FontAwesomeIcon  icon={fa.faSpinner}  className=" text-black    spinner" /></div>
      )}
      <img
        style={loaded ? {} : { display: 'none' }}
        className={className +" fade"}
        src={src}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};

  const getVisitSheet=()=>{
     if(visit_sheets.length==0) return dataVisitSheets[0];
    var VisitSheet  = dataVisitSheets.find(s=>s.id+""===""+visit_sheets[indexVisitSheet]);

     var images= [];
     VisitSheet.medias.forEach(f=>{
        if (f.content_type.includes("image")) images.push(f)
      })
    
     var audio= [];
     VisitSheet.medias.forEach(f=>{
        if (f.content_type.includes("audio")) audio=f
      })
     
     var desc = { title: VisitSheet.title.text_fr,
       description:VisitSheet.texts.map(t=> t.text_fr   ).join(", ")}
    return {...VisitSheet ,...desc,images,audio  };
  }

const images = useMemo(()=>{
  var VisitSheet = getVisitSheet();
   return  VisitSheet.images.length==0 ? [{url:"https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"  }] :VisitSheet.images;
},[visit_sheets,indexVisitSheet])

const getImages =()=>{
  var VisitSheet = getVisitSheet();
   return  VisitSheet.images.length==0 ? [{url:"https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"  }] :VisitSheet.images;
};

  const getImageUrl=()=>{
    var VisitSheet = getVisitSheet();
  if( VisitSheet.images.length>0) return  VisitSheet.images[indexImage].url

  return "https://general-henri-1-paris.s3.fr-par.scw.cloud/small-woman.jpg"  ;
  }


  return <><div className="   w-screen h-screen bg-black bg-opacity-50 fixed top-0 left-0 flex flex-col justify-center items-center" >
    <div className="landscape:hidden  relative p-4 flex flex-col items-center  justify-center box-border  w-full" >
<div className={'flex flex-col  p-2 rounded-lg shadow  relative justify-center items-center  box-border w-full fade  ' + (visit_sheets.length==0 ?"bg-orange-500  text-white":"bg-white")} > 
<div className="flex flex-row w-full px-4 justify-between items-center">
<FontAwesomeIcon icon={fa.faChevronLeft} onClick={()=>{setIndexImage(0); setIndexVisitSheet( (indexVisitSheet-1 + visit_sheets.length ) %  visit_sheets.length)}} className={" flex-initial p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 "+ (visit_sheets.length>1? "":" opacity-0" )}  />
     <div  className=' text-base font-bold text-center flex-1'  >{ getVisitSheet().title}</div>
     <FontAwesomeIcon icon={fa.faChevronRight} onClick={()=>{setIndexImage(0);setIndexVisitSheet( (indexVisitSheet+1 )% visit_sheets.length)}} className={" flex-initial p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10  "+ (visit_sheets.length>1? "":" opacity-0" )}  /> 
</div>
<div   className='flex flex-col items-center w-full box-border'> 
  <div   className='flex flex-row items-center w-full p-2 box-border'>   
  <FontAwesomeIcon icon={fa.faChevronLeft} onClick={()=>setIndexImage( (indexImage-1 + getVisitSheet().images.length ) % getVisitSheet().images.length)} className={" bg-opacity-50 p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 -mr-8 "+ (getVisitSheet().images.length>1? "":" opacity-0" )}   />
{ false ?  <Image src={getImageUrl() }   className="w-full box-border rounded shadow max-h-[80rem] object-cover" /> 
  : <Carousel  className="w-full box-border rounded shadow   h-80 max-h-[40vh] object-cover" infiniteLoop="true" selectedItem={indexImage}  showStatus={false} showArrows={false} >
    { getImages().map((img,i)=> <Image src={img.url }   className="w-full box-border rounded shadow  max-h-[40vh]  h-80  object-cover"  key= { i}/>   ) }
  </Carousel>
  }  <FontAwesomeIcon icon={fa.faChevronRight} onClick={()=>setIndexImage( (indexImage+1 )% getVisitSheet().images.length)} className={"bg-opacity-50  text-black p-2 rounded-full bg-gray-300 w-4 h-4  -ml-8 z-10 "+ (getVisitSheet().images.length>1? "":" opacity-0" )}  /> 
  </div>  

</div>
<div className=' text-xs  h-20 overflow-hidden p-4 mb-1'  >{ getVisitSheet().description.substring(0,150)}</div>

  
  </div>  
  <div className='w-full m-1'>  <Pager nb={visit_sheets.length} nbselected={indexVisitSheet}  /></div>
    <FontAwesomeIcon icon={fa.faClose } onClick={()=>setSelectedMarker(-1)} className=" p-2 text-black rounded-full bg-gray-300 w-4 h-4 absolute right-0 top-0" />
  
  </div>

  <div className=" portrait:hidden  flex flex-col items-center justify-center" >

    <div className=" max-w-full  relative p-4 flex flex-row items-center border-box" >
    <FontAwesomeIcon icon={fa.faChevronLeft} onClick={()=>{setIndexImage(0);setIndexVisitSheet( (indexVisitSheet-1 + visit_sheets.length ) %  visit_sheets.length)}} className={"p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 m-1 "+ (visit_sheets.length>1? "":" opacity-0" ) } />
     <div className={'   max-w-full flex flex-row  p-2 rounded-lg shadow  relative fade ' + (visit_sheets.length==0 ?" bg-orange-500 text-white":" bg-white")}  > 

  <div   className='flex flex-row items-center text-xs'>   

  <FontAwesomeIcon icon={fa.faChevronLeft} onClick={()=>setIndexImage( (indexImage-1 + getVisitSheet().images.length ) % getVisitSheet().images.length)} className={" bg-opacity-50 p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 "+ (getVisitSheet().images.length>1? "":" opacity-0" )}  />
   <div className='flex flex-col items-center justify-center'>
   { false ?  <Image src={getImageUrl() }   className="w-80  h-80 rounded shadow mx-[-20px] object-cover "  /> 
  : <Carousel  className="w-80  h-80 rounded shadow mx-[-20px] object-cover " infiniteLoop="true" selectedItem={indexImage}  showStatus={false} showArrows={false} >
    { getImages().map((img,i)=> <Image src={img.url }   className="w-80  h-80 rounded shadow mx-[-20px] object-cover "  key= { i} />   ) }
  </Carousel>
  } 

   </div>
   <FontAwesomeIcon icon={fa.faChevronRight} onClick={()=>setIndexImage( (indexImage+1 )% getVisitSheet().images.length)} className={"bg-opacity-50 p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 "+ (getVisitSheet().images.length>1? "":" opacity-0" )}  /> 
  </div>  



  <div   className='flex flex-col max-w-[20rem] h-80 justify-around p-2'>   

   <div  className=' text-s font-bold  '  >{ getVisitSheet().title}</div>


<div className=' text-xs  h-60 overflow-hidden '  >{ getVisitSheet().description.substring(0,650)}</div>


  </div>
  </div>  
  <FontAwesomeIcon icon={fa.faChevronRight} onClick={()=>{setIndexImage(0);setIndexVisitSheet( (indexVisitSheet+1 )% visit_sheets.length)}} className={"p-2 text-black rounded-full bg-gray-300 w-4 h-4 z-10 m-1 "+ (visit_sheets.length>1? "":" opacity-0" )}  /> 
  <FontAwesomeIcon icon={fa.faClose } onClick={()=>setSelectedMarker(-1)} className=" p-2 text-black rounded-full bg-gray-300 w-4 h-4 absolute right-8 top-0" />
  </div>
<div className='w-full -m-1 '>  <Pager nb={visit_sheets.length} nbselected={indexVisitSheet}  /></div>
  </div>
  { 
getVisitSheet().audio &&
<audio
controls
src={  getVisitSheet().audio.url }>
  
</audio>
}
  </div>
  </>


}