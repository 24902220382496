import logo from '../logo512.png';
import '../css/Home.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { TileLayer, Popup, MapContainer, Marker } from 'react-leaflet'
import LoginForm from '../components/LoginForm';
import { useEffect, useState } from 'react';
import url_server from '../service/url_server';
import { Link } from 'react-router-dom';

function Home() {

  const fields = [{ name: "name" },
  { name: "slug", placeholder: "unique shortcut" },
  { name: "activity_id", placeholder: "99" , inSchema: true,},
  { name: "agency_id", placeholder: "5" },
  { name: "minZoom", inSchema: true, placeholder: "0" },
  { name: "maxZoom", inSchema: true, placeholder: "6" }];
  const [showLogin, setShowLogin] = useState(false);
  const [token, setToken] = useState(sessionStorage.getItem('token'));
  const [maps, setMaps] = useState([]);
  const [newMap, setNewMap] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [modifiedMaps, setModifiedMaps] = useState([]);
  const [Saving, setSaving] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(null)

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewSrcs, setPreviewSrcs] = useState([])
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);

    var file = event.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) { setPreviewSrc(reader.result) }
  };

  const changeHandlers = (event, index) => {
    selectedFiles[index] = event.target.files[0];
    setSelectedFiles([...selectedFiles]);


    var file = event.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      previewSrcs[index] = reader.result
      setPreviewSrc([...previewSrcs])
    }
  };
  useEffect(() => {

    async function fetchData() {
      setLoading(true);
      const response = await fetch(url_server + 'api/maps');
      const result = await response.json();
      setMaps(result.list);
      setModifiedMaps(result.list.map(r => null));
      setSelectedFiles(result.list.map(r => null));
      setPreviewSrcs(result.list.map(r => null));
      setLoading(false);
    }

    if (token) fetchData();

  }, [token]
  );

  const resetToken = async () => {
    setToken(null);
    sessionStorage.removeItem("token")
  }


  const saveNewMap = async () => {
    setSaving(true);
    const formData = new FormData();
    formData.append('Map', JSON.stringify(newMap));
    formData.append('File', selectedFile);

    const response = await fetch(
      url_server + 'api/createmap',
      {
        method: 'POST',
        body: formData,
        headers: {
          //     "Content-Type": "multipart/form-data"
        }
      }
    );
    const result = await response.json();
    setNewMap(null);
    setMaps(result.list);
    setSaving(false);

  }
  const duplicateMap = async (index)=>{
    if( !window.confirm(`Are you sure you want to duplicate`))return
    var map = maps[index];
    setLoading(true);
    const formData = new FormData();
    formData.append('Map', JSON.stringify(map));

    const response = await fetch(
      url_server + 'api/duplicatemap/',
      {
        method: 'POST',
        body: formData,
        headers: {
          //     "Content-Type": "multipart/form-data"
        }
      }
    );
  
    const result = await response.json();
    console.log(result);
    setMaps(result.list);
    setModifiedMaps(result.list.map(r => null));
    setSelectedFiles(result.list.map(r => null));
    setPreviewSrcs(result.list.map(r => null));

    setLoading(false);
    console.log("ok");

  }
  const saveMap = async (index) => {
    var map = maps[index];
    map.schema.name = map.name ;
    console.log(map);
    console.log(map.backup);
    map.blnRecreateTiles = map.backup && (map.backup.schema.minZoom !=map.schema.minZoom || map.backup.schema.maxZoom !=map.schema.maxZoom) ;
    delete map.backup
    modifiedMaps[index] = "saving"
    setModifiedMaps(modifiedMaps.map(l=>l))
    setMaps(maps.map((l,i)=> i==index ? map:   l));
    const formData = new FormData();
    formData.append('Map', JSON.stringify(map));
    if (selectedFiles[index]) formData.append('File', selectedFiles[index]);

    const response = await fetch(
      url_server + 'api/updatemap/' + map.id,
      {
        method: 'POST',
        body: formData,
        headers: {
          //     "Content-Type": "multipart/form-data"
        }
      }
    );
  
    const result = await response.json();
    console.log(result);
    modifiedMaps[index] = null
    setModifiedMaps(modifiedMaps.map(l=>l))
    selectedFiles[index] = null
    setSelectedFiles(selectedFiles.map(l=>l));
    previewSrcs[index] = null
    setPreviewSrcs(previewSrcs.map(l=>l));
    setMaps(result.list);
    console.log("ok");

  }


  const deleteMap = async (index) => {
    var value = prompt("Saisir le nom de la map pour confirmer la suppression '" + maps[index].name + "'");
    if (value.trim() !== maps[index].name.trim()) return;

    setLoading(true);

    const response = await fetch(
      url_server + 'api/map/' + maps[index].id,
      {
        method: 'DELETE',

        headers: {
          //     "Content-Type": "multipart/form-data"
        }
      }
    );

    const result = await response.json();
  
  setMaps(  result.list);
  setModifiedMaps(result.list.map(r => null));
  setSelectedFiles(result.list.map(r => null));
  setPreviewSrcs(result.list.map(r => null));

  setLoading(false);
 

 
  }


const cancelEditMap = (index)=>{


  if(  maps[index].backup) {
    const map  =maps[index].backup
     setMaps(maps.map((v, i) => i == index ? map : v));
    }
    setPreviewSrcs(previewSrcs.map((v, i) => i == index ? null : v));
    setSelectedFiles(selectedFiles.map((v, i) => i == index ? null : v));
  setModifiedMaps(modifiedMaps.map((v, i) => i == index ? null : v));

}


  const handleChange = (event, kv) => {
    //  console.log(event.target.value,kv)
    newMap[kv[0]] = event.target.value;

    setNewMap(newMap);
  }
  const handleChangeMap = (event, index, field, inSchema = false) => {
    //  console.log(event.target.value,kv)
if( ! maps[index].backup)  maps[index].backup=JSON.parse(JSON.stringify(maps[index])); 

    if (inSchema)
      maps[index].schema[field] = event.target.value;
    else
      maps[index][field] = event.target.value;
    setMaps(maps);
  }

  const fieldValue = (index, field, inSchema = false) => {
    //  console.log(event.target.value,kv)
    if (inSchema)
      return maps[index].schema[field]
    else
      return maps[index][field]
  }
  const createMap = () => {
    setNewMap(
      {
        agency_id: 5,
        status: 'active',
        name: 'NEW MAP',
        schema: {
          "width": 0,
          "height": 0,
          "minZoom": 0,
          "maxZoom": 1,

          markers: [],
          styles: {},
          icons: {},
          resources: []
        }
      }
    );

  }





  const position = [43.5904407, 1.4456872]

  return (<>
    <MapContainer id="map" center={position} zoom={17} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position}>
        <Popup>
          A pretty CSS3 popup. <br /> Easily customizable.
        </Popup>
      </Marker>
    </MapContainer>
    {token ?

      <div className="absolute top-0 left-0 w-screen h-screen z-[1000] flex flex-row items-center justify-center">


        <>{!newMap ?
          <header className=" fade text-blue-900  bg-white  p-2 rounded flex flex-col items-center justify-center">
            <img src={logo} className=" w-10 h-10 " alt="logo" />
            <p className="text-sm font-bold" >HenriTrip</p>
            <p className="text-sm font-bold">  Map Interactive Builder
            </p>
            {
              Loading ? <p>Chargement des Maps en cours... </p> :
                <> <div className='w-[50vw] max-h-[50vh] overflow-y-auto '>{maps.map((map, index) =>
                  <div className='flex flex-row p-1 w-full'    key={map.id}>

                    <div className='flex flex-col  items-center  '><img src={previewSrcs[index] ?? url_server + "maps/map_" + map.id + "/tiles/0/0/0.png"} className='h-20 w-20 border p-2 rounded shadow border-black' />
                      {modifiedMaps[index] == "editing" ? <div className='mt-[-20px] bg-white shadow rounded-full p-2 text-blue-500 text-xl flex flex-row' ><FontAwesomeIcon icon={fa.faEdit} className="mx-1" onClick={() => { document.getElementById("file" + index).click(); }} />
                        <input id={"file" + index} type="file" name="file" className="hidden" onChange={(event) => changeHandlers(event, index)} /></div> : <></>}
                    </div>


                    <div className='flex flex-col p-1 w-full'>

                      {fields.map(field => (
                        <div className='text-xs p-1'   key={field.name}>{field.name} {modifiedMaps[index] == "editing" ? <input type="text" className='text-xs shadow appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' defaultValue={fieldValue(index, field.name, field.inSchema)} onChange={(event) => handleChangeMap(event, index, field.name, field.inSchema)} placeholder={field.placeholder} /> :
                          <span>{fieldValue(index, field.name, field.inSchema)}</span>}  </div>
                      ))}
                      <div className='flex flex-row p-1 w-full justify-end items-end'>
                        { modifiedMaps[index] == "saving" ? 
                        <>{ ( map.blnRecreateTiles || previewSrcs[index]) ?    <div>Saving && Regenerating Tiles ...</div> : <div>Saving ...</div>}</> :
                          <>{!modifiedMaps[index] ? <div className='m-1 bg-white shadow rounded p-2 text-gray-500 text-xs flex flex-row' onClick={() => { setModifiedMaps(modifiedMaps.map((v, i) => i == index ? "editing" : v)) }}> <FontAwesomeIcon icon={fa.faEdit} className={"text-blue-500 mx-1"}  />   Editer </div>
                            :
                            <>{modifiedMaps[index] == "editing" ? <><div className='m-1 bg-white shadow rounded p-2 text-blue-500 text-xs flex flex-row' onClick={() => { saveMap(index) }}><FontAwesomeIcon icon={fa.faSave} className="mx-1"  />Save</div>
                              <div className='m-1 bg-white shadow rounded p-2 text-red-500 text-xs flex flex-row' onClick={() => {   cancelEditMap(index)  }} ><FontAwesomeIcon icon={fa.faCancel} className="mx-1 "  />Cancel</div></> : <></>
                            }</>}
                            <div className='m-1 bg-white shadow rounded p-2 text-red-500 text-xs flex flex-row'  onClick={() => deleteMap(index)} ><FontAwesomeIcon icon={fa.faMinusCircle} className="mx-1" />Supprimer</div>
                            <div className='m-1 bg-white shadow rounded p-2 text-orange-500 text-xs flex flex-row'  onClick={() => duplicateMap(index)} ><FontAwesomeIcon icon={fa.faCopy} className="mx-1" />Dupliquer</div>                           <Link to={"/map/" + (map.slug ?? map.id)}><div className=' m-1  text-xs w-full p-2 shadow rounded'><FontAwesomeIcon icon={fa.faLink} className="mx-1" />Ouvrir ({map.slug ?? map.id})</div></Link></>
                        }
                      </div>
                    </div>
                  </div>


                )

                }
                </div>
                  <div className='bg-green-500 text-white p-1 rounded mt-2  text-xs' onClick={createMap}> Ajouter map </div>
                </>
            }

          </header> :


          <header className="relative fade text-blue-900  bg-white  p-2 rounded flex flex-col items-center justify-center text-xs">
            {!Saving ?
              <><p className="text-sm font-bold" >Nouvelle map</p>
                {

                  Object.entries(newMap).filter(kv => typeof (kv[1]) == "number" || typeof (kv[1]) == "string").map(kv =>
                    <div className="flex flex-col" key={kv[0]} ><div className='text-xs mt-2' >{kv[0]}</div>
                      <input type='text' className='text-xs shadow appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name={kv[0]} defaultValue={kv[1]} onChange={(event) => { handleChange(event, kv) }} />
                    </div>

                  )}
                <input id="file" type="file" name="file" className="hidden" onChange={changeHandler} />
                {isSelected && previewSrc ? (

                  <img onClick={() => {
                    document.getElementById("file").click();

                  }} className="m-2 w-60 h-40 object-cover" src={previewSrc} />

                ) : (
                  <p className="m-2 w-60 h-40  p-2 bg-green-500 round text-white flex flex-row items-center justify-center" onClick={() => {
                    document.getElementById("file").click();

                  }} >Select a file to show details</p>
                )}
                <div>
                  <button className='bg-green-500 text-white p-1 rounded mt-2  text-xs' onClick={saveNewMap}>Créer Map</button>
                </div>
                <FontAwesomeIcon icon={fa.faClose} onClick={() => setNewMap(null)} className=" p-2 text-black rounded-full  w-4 h-4 absolute right-0 top-0" />
              </> :
              <p className="text-sm font-bold" >Création de la map en cours...</p>
            }
          </header>
        }</>

      </div>
      :
      <div className="absolute top-0 left-0 w-screen h-screen z-[1000] flex flex-row items-center justify-center">
        {showLogin ?
          <LoginForm setToken={setToken} setShowLogin={setShowLogin} /> :
          <header className=" fade text-blue-900  bg-white  p-2 rounded flex flex-col items-center justify-center">
            <img src={logo} className=" w-10 h-10 " alt="logo" />
            <p className="text-sm font-bold" >HenriTrip</p>
            <p className="text-sm font-bold">  Map Interactive Builder
            </p>
            <div className='bg-blue-500 text-white p-1 rounded mt-2' onClick={() => { setShowLogin(true) }}> Login </div>
          </header>}

      </div>
    }

    {token && <div className='fixed bottom-0 right-0 z-[1000] p-4  flex flex-col justify-end items-end'  ><div className='m-1 bg-white shadow rounded p-2 text-gray-500 text-xs flex flex-row' > <FontAwesomeIcon icon={fa.faUserCircle} className="text-red-500 mx-1" onClick={resetToken} />
      Logout</div></div>}



  </>


  );
}

export default Home;