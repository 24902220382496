

import { useEffect, useState} from 'react';


import "../css/Map.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'


export default ({ selectedMarker,data, tools, setSelectedMarker ,data0}) => {

  console.log("MARKER UPDATE" , selectedMarker)
const [newCSS, setNewCSS]=useState("");
const [newSite, setNewSite]=useState("");
const [newPI, setNewPI]=useState("");
const [showCSS, setShowCSS]=useState(false);
  const close = () => {
  setSelectedMarker(-1);
  }

  const save = () => {
    setSelectedMarker(-1);
  }

  const cancel = () => {
    setSelectedMarker(-1);
    tools.setData(data0.schema);
  }

  const handleChange = (event, kv) => {
    //  console.log(event.target.value,kv)
    var data_ = { ...tools.getData() };
  
  
    if(kv[0]=="coordinates"){
      var c =  event.target.value.split(",");
      if (c.length==2)
      data_.markers[selectedMarker][kv[0]] =[  parseInt( c[0]), parseInt( c[1])] ;
      else return;
    }
    else if(kv[0]=="zoommin"){
      var type_=  data_.markers[selectedMarker]["type"]
      data_.markers =   data_.markers.map(m=>{
        if(m.type==type_) m.zoommin=  event.target.value;
        return m
      })
    }
    else
    { 
      data_.markers[selectedMarker][kv[0]] = event.target.value;
    
    }
    tools.setData(data_);
  }

  const handleChangeCSS = (event, kv) => {
    //  console.log(event.target.value,kv)
    var data_ = { ...data }
    var css = event.target.value.split(":");
    console.log(css)
    if (css.length != 2) return;
    data_.styles["map-" + data_.markers[selectedMarker].type][kv[0].trim()] = css[1].trim();

    tools.setData(data_);
  }

  const delCSS= (key) => {

    //  console.log(event.target.value,kv)

    var data_ = { ...data }
  
  var style={...data_.styles["map-" + data_.markers[selectedMarker].type]}
console.log(key,style);
    delete style[key] ;
    console.log(style);
    data_.styles["map-" + data_.markers[selectedMarker].type]=style;

    tools.setData(data_);
  }
  const handleChangenewCSS = (event) => {
console.log(event.target.value)
    setNewCSS( event.target.value);
  }
  const addNewCSS= () => {
     console.log("addNewCSS "  + newCSS)
    var data_ = { ...data };
    var css = newCSS.split(":");
    if (css.length!=2) return
    console.log("addNewCSS Validate "  ,css)

if(  !data_.styles["map-" + data_.markers[selectedMarker].type])   data_.styles["map-" + data_.markers[selectedMarker].type]={}

    data_.styles["map-" + data_.markers[selectedMarker].type][css[0].trim()] = css[1].trim() ;
    
    setNewCSS("");
    tools.setData(data_);
  }

  const handleChangeHTML = (event) => {
    //  console.log(event.target.value,kv)
    var data_ = { ...data }
    data.icons[data.markers[selectedMarker].html] = event.target.value;

    tools.setData(data_);
  }

  const delSite= (index) => {

    //  console.log(event.target.value,kv)
    var data_ = { ...data }
    var siteid =  data_.markers[selectedMarker].sites[index]
  var sites = data_.markers[selectedMarker].sites.filter( (site,i)=> i!=index)
  data_.markers[selectedMarker].sites=sites;
  data_.resources = data_.resources.filter(ts=>   ts.id!=siteid || ts.resource_type=="VisitSheet"); 

  tools.setData({...data_});
  }
  
  const handleChangenewSite = (event) => {

    setNewSite(event.target.value);
  }
  const addSite= () => {

    //  console.log(event.target.value,kv)

    var data_ = { ...data }
    if(! data_.markers[selectedMarker].sites) data_.markers[selectedMarker].sites=[]
    data_.markers[selectedMarker].sites.push(newSite)
    if(!data_.resources) data_.resources=[];
    data_.resources.push({"id":  newSite, "resource_type":"TouristicSite"})
 
       setNewSite("");
       tools.setData({...data_});
  }

  const delVisitSheet= (index) => {

    //  console.log(event.target.value,kv)
    var data_ = { ...data }
    var PIid =  data_.markers[selectedMarker].visit_sheets[index]
  var visit_sheets = data_.markers[selectedMarker].visit_sheets.filter( (pi,i)=> i!=index)
  data_.markers[selectedMarker].visit_sheets=visit_sheets;
  data_.resources = data_.resources.filter(ts=>   ts.id!=PIid || ts.resource_type!="VisitSheet" ); 

  tools.setData({...data_});
  }
  

  const [ entries,setEntries]=useState([])
  const [ entriesCSS,setEntriesCSS]=useState([])

  useEffect(()=>{
    if (selectedMarker == -1) return 
    setEntries(Object.entries(data.markers[selectedMarker]).filter(kv=>(kv[0]!="sites" && kv[0]!="id")) );
  
    setEntriesCSS(Object.entries(data.styles["map-" + data.markers[selectedMarker].type] ?? {}));
    console.log(selectedMarker,entries)
  
  },[data,selectedMarker])
  
  if (selectedMarker == -1) return <></>;


  return <div  className=' h-6/12'><div className='flex flex-col bg-white  p-2 rounded-lg shadow w-80'  >
   
    <div className="w-full justify-between flex flex-row justify-end"  >
    <div  className="text-xs"> id : {selectedMarker}</div>
      <FontAwesomeIcon icon={fa.faClose} onClick={close} className="p-1  text-2xl text-red-900" />
    </div>
    {

      entries.map(kv =>
        <div className="flex flex-col" key={kv[0]} ><div className='text-xs mt-2' >{kv[0]}</div>
          <input type='text' className='text-xs shadow appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name={kv[0] }  value={kv[1]}   onChange={(event) => { handleChange(event, kv) }} />
        </div>

      )}
{
      data.markers[selectedMarker].type
        ?
    <><div  className='flex flex-row items-center ' ><div className=' mt-2  rounded w-11/12 py-1 px-1 text-gray-700  ' >CSS</div>
    <FontAwesomeIcon icon={!showCSS ?  fa.faChevronDown : fa.faChevronUp} className="p-2 text-black" onClick={() => { setShowCSS(!showCSS) }} /></div>
    {
showCSS ?
      <>{entriesCSS.map(kv =>

       <div className="flex flex-row"  key={kv[0]} ><input type='text' className='text-xs shadow appearance-none border rounded w-11/12 py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name={kv[0]} value={kv[0] + " : " + kv[1]} onChange={(event) => { handleChangeCSS(event, kv) }} />
<FontAwesomeIcon icon={fa.faMinusCircle} className="p-2 text-red-500" onClick={() => { delCSS(kv[0]) }} />
       </div>


      )}     <div className="flex flex-row" >
      <input type='text' className='text-xs shadow appearance-none border rounded w-11/12 py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name="newCSS" placeholder='property : value'  value={newCSS}  onChange={handleChangenewCSS} />
<FontAwesomeIcon icon={fa.faPlusCircle} className={ "p-2 " + (newCSS ? " text-green-500" :" text-gray-200") }  onClick={ newCSS ?  addNewCSS:null} /></div></>: <></>
}</>    :<></>}
<div className='text-xs mt-2' >Sites</div>
{
  data.markers[selectedMarker].sites   ?
    <>
    {

      data.markers[selectedMarker].sites .map((siteid,index) =>

       <div className="flex flex-row"  key={index} > <a className='text-xs shadow appearance-none border rounded w-11/12 py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'   href={  'https://www.henritrip.fr/touristic-site/' + siteid } target="_blank"  >{siteid}</a>
<FontAwesomeIcon icon={fa.faMinusCircle} className="p-2 text-red-500" onClick={() => { delSite(index) }} />
       </div>

      )}
  </>    : <></>

}
<div className="flex flex-row" >
       <input type='text' className='text-xs shadow appearance-none border rounded w-11/12 py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name="newSite" placeholder='.. touristic_site_id ' value={newSite} onChange={handleChangenewSite} />
  
    <FontAwesomeIcon icon={fa.faPlusCircle} className={ "p-2 " + (newSite ? " text-green-500" :" text-gray-200") }  onClick={ newSite ?  addSite:null} />
  </div>


  <div className='text-xs mt-2' >Points d'intérêt</div>
  {
  data.markers[selectedMarker].visit_sheets   ?
    <>
    {

      data.markers[selectedMarker].visit_sheets .map((vs_id,index) =>

       <div className="flex flex-row"  key={index} >{   tools.getVisitSheetTitle(   vs_id) }
<FontAwesomeIcon icon={fa.faMinusCircle} className="p-2 text-red-500" onClick={() => { delVisitSheet(index) }} />
       </div>

      )}
  </>    : <></>

}
<div className="flex flex-row"  onClick={ tools.showVisitSelect}  >
      Lier une visite
    <FontAwesomeIcon icon={fa.faPlusCircle} className={ "p-2 text-green-500"  } />
  </div>





{
      data.markers[selectedMarker].html
        ?
        <><div className='text-xs mt-2' >HTML / ICON SVG</div>
          <textarea name="html" className='text-xs h-40 shadow appearance-none border rounded w-full py-1 px-1 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' onChange={handleChangeHTML} 
           defaultValue={data.icons[data.markers[selectedMarker].html] ?? ""} ></textarea>
        </>
        : <></>
    }



  </div></div>

}