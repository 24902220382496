
import { TileLayer, useMap, Popup, MapContainer,useMapEvents } from 'react-leaflet'
import { MarkerLayer } from "react-leaflet-marker";
import { useEffect, useState, useContext, createContext, useMemo } from 'react';
import { useParams } from "react-router-dom";
import RasterCoords from 'leaflet-rastercoords'
import "leaflet/dist/leaflet.css";
import "../css/Map.css"
import DivMarker from '../components/DivMarker';
import url_server from '../service/url_server';

export default function ComponentTileLayer({ zoom,data, id, tools, setData, setSelectedMarker, data0 ,editable}) {
  const map = useMap();
  const [markers, setMarkers] = useState([]);
const [zoomLevel, setZoomLevel] = useState(zoom);
  const mapEvents = useMapEvents({ 
    zoomend: () => {
        setZoomLevel(mapEvents.getZoom());
    },
  /*  click: (event) => {
      alert("You clicked the map at " + event.latlng);
    }
    */
});

  const dim = [
    data.width, // original width of image `karta.jpg`
    data.height   // original height of image
  ]
  console.log(data);

  // assign map and image dimensions
  const rc =  useMemo(()=>{ 
return  new RasterCoords(map, dim);
  },[map,dim]);

useEffect(() => {
  console.log("change setView");
  map.setView(rc.unproject([dim[0] * 0.5, dim[1] * 0.5]), zoom)
  
},[])


  if (!data.id) {
    return <></>
  }


  tools = {...tools,
    getRc: function () {
      return rc;
    },
    convertLatLng: function (latlng) {

      var p = rc.project(latlng, rc.map.getZoom());
      p.x *= 1;
      p.y *=  1;

      return [Math.round(p.x), Math.round(p.y)];
    }
    }


//  console.log('map center:', map.getCenter())
console.log("Zoom", zoomLevel);
console.log(rc.getMaxBounds());
map.setMaxBounds(     );
  return <><TileLayer
  //tileSize ="255"
    noWrap='true'
    bounds={ [[0,0],[-255,255]]}
  // bounds={ rc.getMaxBounds()}
    maxNativeZoom={rc.zoomLevel()}
   
    attribution='&copy; <a href="https://www.henritrip.fr" target=_blank> Powered by HenriTrip</a> '
    url={url_server +  "maps/map_" + id + "/tiles/{z}/{x}/{y}.png"}
  />
    <MarkerLayer>
      {data.markers.filter(m=>m.zoommin<=zoomLevel).map((marker_) => {

        return <DivMarker key={`marker-${marker_.id}`} tools={tools} marker_={ marker_ } zoom={zoomLevel} editable={editable} data={data} setData={setData}
        />
      })}

    </MarkerLayer>
  </>
}